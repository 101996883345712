import React from 'react';
import cx from 'classnames';
import styles from './Alert.module.css';
import infoImage from '../../assets/images/info.svg';

const Alert = ({ message, options, style, close }) => {
  const alertClass = cx(styles.alert, {
    [styles.alertError]: options.type === 'error',
    [styles.alertInfo]: options.type === 'info'
  });

  return (
    <div className={alertClass} style={{ ...style }}>
      {options.type === 'info' && (
        <img className={styles.infoIcon} src={infoImage} alt="" />
      )}
      <span className={styles.message}>{message}</span>
      <button onClick={close} className={styles.button} />
    </div>
  );
};

export default Alert;
