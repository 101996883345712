/* eslint-disable react/jsx-no-target-blank */
import * as Yup from "yup";

import React from "react";
import cx from "classnames";
import styles from "./DailyAffirmationsForm.module.css";
import { withFormik } from "formik";

const DailyAffirmationsForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    captcha,
  } = props;

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.row}>
        <div
          className={cx(styles.formGroup, {
            [styles.formGroupError]: errors.name && touched.name,
          })}
        >
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Your Name"
            className={styles.formControl}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
          />
          {errors.name && touched.name && (
            <label className={styles.validationError}>{errors.name}</label>
          )}
        </div>
        <div
          className={cx(styles.formGroup, {
            [styles.formGroupError]: errors.email && touched.email,
          })}
        >
          <input
            id="email"
            type="text"
            name="email"
            placeholder="Your Email Address"
            className={styles.formControl}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
          />
          {errors.email && touched.email && (
            <label className={styles.validationError}>{errors.email}</label>
          )}
        </div>
      </div>
      <div className={styles.btnContainer}>
        <button className={styles.btn} type="submit" disabled={isSubmitting}>
          Sign Up
        </button>
      </div>
      {captcha && (
        <p className={styles.captchaPolicy}>
          This site is protected by reCAPTCHA and the Google{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener"
          >
            Terms of Service
          </a>{" "}
          apply.
        </p>
      )}
    </form>
  );
};

export default withFormik({
  mapPropsToValues: ({ values: { name = "", email = "" } }) => ({
    name,
    email,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Please enter your name."),
    email: Yup.string()
      .required("Please enter your email address.")
      .email("Please enter a valid email address."),
  }),
  handleSubmit: (values, { resetForm, setSubmitting, props }) => {
    const { onSubmitHandler } = props;
    onSubmitHandler(values, setSubmitting);
  },
  enableReinitialize: true,
})(DailyAffirmationsForm);
