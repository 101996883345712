import PropTypes from "prop-types";

const dataShape = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  video: PropTypes.string,
  subtopics: PropTypes.arrayOf(PropTypes.string),
};

export const dataPropTypes = PropTypes.shape(dataShape);

const routeShape = {
  name: PropTypes.string.isRequired,
  location: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  data: dataPropTypes,
};

export const routePropTypes = PropTypes.shape(routeShape);

export const introPropTypes = {
  data: dataPropTypes.isRequired,
};

export const settingsPropTypes = {
  data: dataPropTypes.isRequired,
  onBackgroundChange: PropTypes.func.isRequired,
};

export const whatTypeOfLossPropTypes = {
  data: dataPropTypes.isRequired,
  routes: PropTypes.arrayOf(routePropTypes).isRequired,
};

export const topicPropTypes = {
  data: PropTypes.shape({
    ...dataShape,
    subtopics: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export const dailyAffirmationsPropTypes = {
  data: PropTypes.shape({
    ...dataShape,
  }).isRequired,
  signed: PropTypes.bool.isRequired,
  onSignup: PropTypes.func.isRequired,
  captcha: PropTypes.bool.isRequired,
};
