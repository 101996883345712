import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import isMobile from '../../utils/is-mobile';
import styles from './Footer.module.css';
import './Footer.css';
import iconPlay from '../../assets/images/play.svg';
import iconPause from '../../assets/images/pause.svg';
import iconNext from '../../assets/images/next.svg';
import iconPrev from '../../assets/images/prev.svg';

const topicChangeLabel = 'Jump to a new topic';

const mobileTopicNavigation = (topics, handleSelectChange) => (
  <label className={styles.mobileNavigation}>
    <select
      defaultValue=""
      onChange={e => handleSelectChange(e, e.target.value)}
      className={styles.mobileNavigationSelect}
    >
      <option value="">{topicChangeLabel}</option>
      {topics.map(({ name, data, location }) => (
        <option key={name} value={location.path}>
          {data.title}
        </option>
      ))}
    </select>
  </label>
);

const desktopTopicNavigation = topics => (
  <div className={styles.navigation}>
    <p className={styles.navigationLabel}>{topicChangeLabel}</p>
    <ul className={styles.navigationDropdown}>
      {topics.map(({ name, data, location }) => (
        <li key={name}>
          <Link
            className={styles.navigationDropdownLink}
            to={`${location.path}${window.location.search}`}
          >
            {data.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    const { routes } = this.props;

    this.state = {
      routes: routes,
      topics: routes.filter(
        _ => _.name.indexOf('topic_') === 0 || _.name === 'daily_affirmations'
      )
    };
  }

  componentDidMount = () => {
    this.setNextAndPrevRoutes();
  };

  componentDidUpdate = prevProps => {
    if (this.props.location !== prevProps.location) {
      this.setNextAndPrevRoutes();
    }
  };

  getPrevAndNextRoutes = () => {
    const { location } = this.props;
    const { routes } = this.state;
    const currentIndex = routes.findIndex(
      _ => _.location.path === location.pathname
    );

    const prevRoute =
      currentIndex - 1 >= 0
        ? routes[currentIndex - 1].location.path
        : undefined;

    const nextRoute = routes[currentIndex + 1]
      ? routes[currentIndex + 1].location.path
      : undefined;

    return {
      prevRoute,
      nextRoute
    };
  };

  setNextAndPrevRoutes = () => {
    const prevAndNext = this.getPrevAndNextRoutes();

    this.setState(prevState => ({
      ...prevState,
      ...prevAndNext
    }));
  };

  changeTopic = (e, value) => {
    if (value) {
      this.props.history.push(`${value}${window.location.search}`);
    }

    e.target.value = '';
  };

  handlePrevClick = () => {
    if (this.state.prevRoute) {
      this.props.history.push(
        `${this.state.prevRoute}${window.location.search}`
      );
    }
  };

  handleNextClick = () => {
    if (this.state.nextRoute) {
      this.props.history.push(
        `${this.state.nextRoute}${window.location.search}`
      );
    }
  };

  render() {
    const { topics, prevRoute, nextRoute } = this.state;
    const playPauseDisabled =
      this.props.location.pathname === '/daily-affirmations';
    const btnPlay = cx(styles.btn, {
      [styles.btnPlay]: true
    });
    const btnPause = cx(styles.btn, {
      [styles.btnPause]: true
    });

    return (
      <footer className={styles.footer}>
        <div className={styles.navigationContainer}>
          {isMobile
            ? mobileTopicNavigation(topics, this.changeTopic)
            : desktopTopicNavigation(topics)}
        </div>
        <div className={styles.controls}>
          <button
            id="btnPrev"
            className={styles.btn}
            onClick={this.handlePrevClick}
            disabled={!prevRoute}
          >
            <img className={styles.btnImage} src={iconPrev} alt="Prev" />
          </button>
          <button id="btnPlay" className={btnPlay} disabled={playPauseDisabled}>
            <img className={styles.btnImage} src={iconPlay} alt="Play" />
          </button>
          <button
            id="btnPause"
            className={btnPause}
            disabled={playPauseDisabled}
          >
            <img className={styles.btnImage} src={iconPause} alt="Pause" />
          </button>
          <button
            id="btnNext"
            className={styles.btn}
            onClick={this.handleNextClick}
            disabled={!nextRoute}
          >
            <img className={styles.btnImage} src={iconNext} alt="Next" />
          </button>
        </div>
      </footer>
    );
  }
}

export { Footer };
export default withRouter(Footer);
