const routes = [
  {
    name: 'intro',
    location: {
      path: '/',
      exact: true
    },
    data: {
      title: 'Welcome to Interactive Aftercare',
      video: 'intro.mp4'
    }
  },
  {
    name: 'settings',
    location: {
      path: '/settings'
    },
    data: {
      title: 'Settings & Preferences',
      subtitle: 'Background',
      video: 'settings.mp4'
    }
  },
  {
    name: 'type_of_loss',
    location: {
      path: '/type-of-loss'
    },
    data: {
      title: 'Choose Type of Loss Experience',
      video: 'what-type-of-loss.mp4'
    }
  },
  {
    name: 'topic_general_grief',
    location: {
      path: '/general-grief'
    },
    data: {
      title: 'General Grief',
      subtitle: 'Overview',
      subtopics: [
        'Grief is not a disease',
        'You will never be the same',
        'You can have a wonderful life'
      ],
      video: 'general-grief.mp4'
    }
  },
  {
    name: 'topic_death_of_spouse',
    location: {
      path: '/death-of-spouse'
    },
    data: {
      title: 'Death of a Spouse',
      subtitle: 'Overview',
      subtopics: [
        'Everything is a reminder',
        'Part of your grieving is to redefine yourself',
        'You are capable of much more than you know'
      ],
      video: 'death-of-a-spouse.mp4'
    }
  },
  {
    name: 'topic_death_of_a_child',
    location: {
      path: '/death-of-a-child'
    },
    data: {
      title: 'Death of a Child',
      subtitle: 'Overview',
      subtopics: [
        'You may feel cheated and somehow responsible',
        'It is the simple small daily steps',
        'Honor your child by living better'
      ],
      video: 'death-of-a-child.mp4'
    }
  },
  {
    name: 'topic_death_of_a_parent',
    location: {
      path: '/death-of-a-parent'
    },
    data: {
      title: 'Death of a Parent',
      subtitle: 'Overview',
      subtopics: [
        'Initially they were everything to you',
        'There is never a good time to lose someone',
        'Allow yourself to go deeper into the sadness'
      ],
      video: 'sudden-death.mp4'
    }
  },
  {
    name: 'topic_suicide',
    location: {
      path: '/suicide'
    },
    data: {
      title: 'Suicide',
      subtitle: 'Overview',
      subtopics: [
        'You can survive and even thrive',
        'You have done nothing to be ashamed of',
        'Successful grieving is a lot of work'
      ],
      video: 'suicide.mp4'
    }
  },
  {
    name: 'topic_loss_during_childhood',
    location: {
      path: '/loss-during-childhood'
    },
    data: {
      title: 'Loss During Childhood',
      subtitle: 'Overview',
      subtopics: [
        'You need to answer their questions honestly',
        'They need someone who will listen',
        'They need reassurance of being loved'
      ],
      video: 'helping-children.mp4'
    }
  },
  {
    name: 'daily_affirmations',
    location: {
      path: '/daily-affirmations'
    },
    data: {
      title: 'Sign Up for Daily Affirmations',
      subtitle: 'Fill in the details below'
    }
  }
];

export default routes;
