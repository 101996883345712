import React, { PureComponent } from "react";

import { ContentBox } from "../ContentBox";
import DailyAffirmationsForm from "./DailyAffirmationsForm";
import ReCAPTCHA from "react-google-recaptcha";
import { dailyAffirmationsPropTypes } from "../../config/prop-types";
import { signup } from "../../utils/api";
import styles from "./DailyAffirmations.module.css";
import { withAlert } from "react-alert";

class DailyAffirmations extends PureComponent {
  static propTypes = dailyAffirmationsPropTypes;

  constructor(props) {
    super(props);

    this._isMounted = false;
    this.captchaRef = React.createRef();
  }

  componentDidMount = () => {
    this._isMounted = true;

    this.btnPlay = document.getElementById("btnPlay");
    this.btnPause = document.getElementById("btnPause");
    this.showPlayButton();
  };

  componentWillUnmount = () => {
    this.hideError();
    this._isMounted = false;
  };

  showPlayButton = () => {
    this.btnPause.style.display = "none";
    this.btnPlay.style.display = "inline-block";
  };

  onSubmit = (data, setSubmitting) => {
    const { onSignup } = this.props;
    const promise = signup(data);

    this.hideError();

    promise
      .then((response) => {
        onSignup(true);
      })
      .catch((e) => {
        if (this._isMounted) {
          this.showError();
          setSubmitting(false);
        }
      });

    return promise;
  };

  onSubmitWithCaptcha = (data, setSubmitting) => {
    this.hideError();

    return this.captchaRef.current
      .executeAsync()
      .then((token) => {
        return this.onSubmit(
          {
            ...data,
            captcha: token,
          },
          setSubmitting
        );
      })
      .catch((e) => {
        if (this._isMounted) {
          this.hideError();
          this.showError();
          setSubmitting(false);
        }
      });
  };

  showError = () => {
    this.alert = this.props.alert.error(
      `An unexpected error has occurred, please try again.`,
      { timeout: 0 }
    );
  };

  hideError = () => {
    if (this.alert) {
      this.alert.close();
    }
  };

  render() {
    const {
      data: { title, subtitle },
      signed,
      captcha,
    } = this.props;

    return (
      <ContentBox title={title} subtitle={subtitle}>
        <div>
          <p>
            To sign up for our daily affirmations, please enter your information
            below and click "Sign-up". We will send you a confirmation email
            verifying your email address. Click the link in the confirmation
            email and you will start receiving these valuable messages.
          </p>

          {!signed && (
            <React.Fragment>
              {captcha && (
                <ReCAPTCHA
                  ref={this.captchaRef}
                  size="invisible"
                  sitekey="6Ld1DrEUAAAAAK792G50PBeLjEy0FZVBTxS6rnlR"
                />
              )}
              <DailyAffirmationsForm
                values={{
                  name: "",
                  email: "",
                }}
                onSubmitHandler={
                  captcha ? this.onSubmitWithCaptcha : this.onSubmit
                }
                captcha={captcha}
              />
            </React.Fragment>
          )}
          {signed && (
            <div className={styles.alertSuccess}>
              <h3 className={styles.alertSuccessTitle}>Thank you.</h3>
              <p className={styles.alertSuccessParagraph}>
                You are now subscribed to receive daily email affirmations.
              </p>
            </div>
          )}
        </div>
      </ContentBox>
    );
  }
}

export default withAlert()(DailyAffirmations);
