import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { whatTypeOfLossPropTypes } from '../../config/prop-types';
import { ContentBox } from '../ContentBox';
import { Video } from '../Video';
import styles from './WhatTypeOfLoss.module.css';

const highlights = [
  12,
  14,
  16,
  19,
  22,
  24,
  27 // end
];

const WhatTypeOfLoss = ({ data: { title, subtitle, video }, routes }) => {
  let videoRef = React.createRef();

  const [currentTime, setCurrentTime] = useState(0);

  const topics = routes.filter(_ => _.name.indexOf('topic_') === 0);

  return (
    <ContentBox
      title={title}
      subtitle={subtitle}
      video={
        <Video video={video} ref={videoRef} onTimeUpdate={setCurrentTime} />
      }
    >
      <div>
        <ul className={styles.navigation}>
          {topics.map(({ name, data, location }, index) => {
            const highlightAt = highlights[index];
            const isHighlighted =
              currentTime >= highlightAt &&
              currentTime <= highlights[index + 1] - 1;

            const linkClass = cx(styles.navigationLink, {
              [styles.navigationLinkHightlight]: isHighlighted
            });

            return (
              <li className={styles.navigationListItem} key={name}>
                <Link className={linkClass} to={location.path}>
                  {data.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </ContentBox>
  );
};

WhatTypeOfLoss.propTypes = whatTypeOfLossPropTypes;

export default WhatTypeOfLoss;
