import "react-app-polyfill/ie11";
import "./utils/polyfills";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import { Provider as AlertProvider, positions, transitions } from "react-alert";

import { Alert } from "./components/Alert";
import App from "./components/App/App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";

const queryfhName = window.location.search
  .replace("?", "")
  .split("&")
  .find((q) => q.indexOf("FuneralHomeName") === 0);

const fhName = queryfhName
  ? decodeURIComponent(queryfhName.split("=").pop()).replace(/\+/g, " ")
  : undefined;

if (fhName) {
  try {
    window.localStorage.setItem("account", fhName);
  } catch (ex) {}
}

const hasCaptcha = window.location.search.indexOf("captcha=false") < 0;

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 60000,
  offset: "0",
  transition: transitions.FADE,
  containerStyle: {
    left: 0,
    maxWidth: 760,
    margin: "70px auto 0",
    textAlign: "right",
    paddingRight: 8,
    position: "absolute",
  },
};

ReactDOM.render(
  <BrowserRouter basename="/e-aftercare">
    <AlertProvider template={Alert} {...options}>
      <App captcha={hasCaptcha} />
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
