import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { topicPropTypes } from '../../config/prop-types';
import { ContentBox } from '../ContentBox';
import { Video } from '../Video';
import { VideoControls } from '../VideoControls';
import styles from './Topic.module.css';

const Subtopics = ({ subtopics }) => {
  return (
    <ul className={styles.subtopicsList}>
      {subtopics.map((subtopic, i) => (
        <li key={i} className={styles.subtopicsListItem}>
          {subtopic}
        </li>
      ))}
    </ul>
  );
};

Subtopics.propTypes = {
  subtopics: PropTypes.arrayOf(PropTypes.string).isRequired
};

class Topic extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: 0,
      duration: 0
    };

    this.videoRef = React.createRef();
  }

  setCurrentTime = currentTime => {
    this.setState({
      currentTime
    });
  };

  setDuration = duration => {
    this.setState({
      duration
    });
  };

  handleSeek = time => {
    this.videoRef.current.handleSeek(time);
  };

  render() {
    const {
      data: { title, subtitle, video, subtopics }
    } = this.props;
    const { currentTime, duration } = this.state;

    return (
      <>
        <ContentBox
          title={title}
          subtitle={subtitle}
          video={
            <Video
              video={video}
              ref={this.videoRef}
              onTimeUpdate={this.setCurrentTime}
              onLoadedMetaData={this.setDuration}
            />
          }
        >
          {subtopics && <Subtopics subtopics={subtopics} />}
        </ContentBox>
        <VideoControls
          currentTime={currentTime}
          duration={duration}
          onSeek={this.handleSeek}
        />
      </>
    );
  }
}

Topic.propTypes = topicPropTypes;

export default Topic;
