import React from 'react';
import styles from './Header.module.css';

const Header = () => (
  <header className={styles.header}>
    <h1 className={styles.header__title}>
      <span className={styles.header__title__highlight}>
        Interactive Aftercare
      </span>{' '}
      Featuring Dr. Virginia Simpson
    </h1>
  </header>
);

export default Header;
