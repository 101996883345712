import React, { Component } from 'react';
import Slider from 'rc-slider';
import styles from './VideoControls.module.css';
import 'rc-slider/assets/index.css';
import './VideoControls.css';

class VideoControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0
    };

    this.dragging = false;
  }

  handleDrag = step => {
    this.dragging = true;

    this.setState({
      currentStep: step
    });
  };

  handleSeek = time => {
    const { onSeek } = this.props;

    if (onSeek) {
      onSeek(time);
    }

    this.dragging = false;
  };

  formatTime = time => {
    const date = new Date(null);
    date.setSeconds(time);

    let minutes = `${date.getMinutes()}`.padStart(2, '0');
    let seconds = `${date.getSeconds()}`.padStart(2, '0');

    return `${minutes}:${seconds}`;
  };

  render() {
    const { currentStep } = this.state;
    const { currentTime, duration } = this.props;
    const sliderValue = this.dragging ? currentStep : currentTime;

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.timeContainer}>
            <div className={styles.time}>
              <span>{this.formatTime(currentTime)}</span> /{' '}
              <span>{this.formatTime(duration)}</span>
            </div>
            <div className={styles.timeSliderContainer}>
              <Slider
                defaultValue={0}
                value={sliderValue}
                min={0}
                max={duration}
                onChange={this.handleDrag}
                onAfterChange={this.handleSeek}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoControls;
