import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContentBox.module.css';

const ContentBox = ({
  video,
  title,
  subtitle,
  children,
  titleStyle = {},
  style = {}
}) => (
  <div className={styles.wrapper} style={style}>
    <div className={styles.box}>
      {video && <div className={styles.video}>{video}</div>}
      <div className={styles.content}>
        <div className={styles.titlesContainer}>
          <h1 className={styles.title} style={titleStyle}>
            {title}
          </h1>
          {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
        </div>
        {children}
      </div>
    </div>
  </div>
);

ContentBox.propTypes = {
  video: PropTypes.element,
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  style: PropTypes.object
};

export default ContentBox;
