import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import { DailyAffirmations } from "../DailyAffirmations";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Intro } from "../Intro";
import { Settings } from "../Settings";
import { Topic } from "../Topic";
import { WhatTypeOfLoss } from "../WhatTypeOfLoss";
import cx from "classnames";
import routes from "../../config/routes";
import styles from "./App.module.css";

const renderRoute = (route, { setBackground, signed, setSigned, captcha }) => (
  <Route
    key={route.name}
    {...route.location}
    render={(routeProps) => {
      switch (route.name) {
        case "intro":
          return <Intro {...routeProps} data={route.data} />;

        case "settings":
          return (
            <Settings
              {...routeProps}
              onBackgroundChange={setBackground}
              data={route.data}
            />
          );

        case "type_of_loss":
          return (
            <WhatTypeOfLoss {...routeProps} data={route.data} routes={routes} />
          );

        case "daily_affirmations":
          return (
            <DailyAffirmations
              {...routeProps}
              data={route.data}
              signed={signed}
              onSignup={setSigned}
              captcha={captcha}
            />
          );

        default:
          return <Topic {...routeProps} data={route.data} />;
      }
    }}
  />
);

const App = (props) => {
  const [background, setBackground] = useState(0);
  const [signed, setSigned] = useState(false);

  let contentClassName = cx(styles.content, {
    [styles.content__bg01]: background === 1,
    [styles.content__bg02]: background === 2,
    [styles.content__bg03]: background === 3,
    [styles.content__bg04]: background === 4,
    [styles.content__bg05]: background === 5,
    [styles.content__bg06]: background === 6,
  });

  return (
    <React.Fragment>
      <div className={styles.app}>
        <Header />
        <div className={styles.contentWrapper}>
          <div className={contentClassName}>
            <Switch>
              {routes.map((route) => {
                return renderRoute(route, {
                  setBackground,
                  signed,
                  setSigned,
                  captcha: props.captcha,
                });
              })}
            </Switch>
          </div>
          <Footer routes={routes} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default App;
