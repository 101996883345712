import axios from "axios";

export const signup = ({ email, name, captcha }) => {
  const qsDomain = (window.location.search || "")
    .substring(1)
    .split("&")
    .find((q) => q.indexOf("domain=") === 0);

  const domain = (qsDomain || "").replace("domain=", "");

  // WE NEED TO SUBMIT AS FORM DATA, OTHERWISE WMS WILL NOT HANDLE IT
  const formData = new FormData();
  formData.append("Name", name);
  formData.append("Email", email);
  formData.append("ServerProcess", 1);

  if (captcha) {
    formData.append("__captcha_response", captcha);
  }

  formData.append("__eaftercare", "true");

  return axios.post(`https://${domain}/forms/affirmations-signup`, formData);
};
