import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './VideoProcessor.module.css';

class VideoProcessor extends PureComponent {
  static propTypes = {
    video: PropTypes.string.isRequired,
    videoAttributes: PropTypes.object,
    outputCanvasStyle: PropTypes.object
  };

  static width = 420;
  static height = 314;

  constructor(props) {
    super(props);

    this.video = React.createRef();
    this.canvasBuffer = React.createRef();
    this.canvasOutput = React.createRef();
    this.interval = undefined;
  }

  componentDidMount = () => {
    this.output = this.canvasOutput.current.getContext('2d');
    this.buffer = this.canvasBuffer.current.getContext('2d');

    this.video.current.addEventListener('play', this.handlePlay, false);
    this.video.current.addEventListener('ended', this.handleEnded, false);
  };

  componentWillUnmount = () => {
    this.video.current.pause();
    this.stopProcessing();
    this.video.current.removeEventListener('play', this.handlePlay, false);
    this.video.current.removeEventListener('ended', this.handleEnded, false);
  };

  handlePlay = () => {
    this.stopProcessing();
    this.interval = setInterval(this.handleFrameProcessing, 40);
  };

  handleEnded = () => {
    this.stopProcessing();
  };

  handleFrameProcessing = () => {
    const buffer = this.buffer;
    const output = this.output;
    const video = this.video.current;

    buffer.drawImage(video, 0, 0);

    // this can be done without alphaData
    // except in Firefox which doesn't like it when image is bigger than the canvas
    const image = buffer.getImageData(
      0,
      0,
      VideoProcessor.width,
      VideoProcessor.height
    );
    let imageData = image.data;
    const alphaData = buffer.getImageData(
      0,
      VideoProcessor.height,
      VideoProcessor.width,
      VideoProcessor.height
    ).data;

    for (var i = 3, len = imageData.length; i < len; i = i + 4) {
      imageData[i] = alphaData[i - 1];
    }

    output.putImageData(
      image,
      0,
      0,
      0,
      0,
      VideoProcessor.width,
      VideoProcessor.height
    );
  };

  stopProcessing = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  render() {
    return (
      <>
        <video
          preload="auto"
          playsInline
          className={styles.video}
          ref={this.video}
          {...this.props.videoAttributes}
        >
          <source
            src={`${process.env.PUBLIC_URL}/videos/${this.props.video}`}
          />
        </video>
        <canvas
          width={VideoProcessor.width}
          height={VideoProcessor.height * 2}
          ref={this.canvasBuffer}
          className={styles.videoBuffer}
        />
        <canvas
          width={VideoProcessor.width}
          height={VideoProcessor.height}
          ref={this.canvasOutput}
          className={styles.videoOutput}
          style={this.props.outputCanvasStyle}
        />
      </>
    );
  }
}

export default VideoProcessor;
