import React, { useState } from 'react';
import cx from 'classnames';
import { settingsPropTypes } from '../../config/prop-types';
import { ContentBox } from '../ContentBox';
import { Video } from '../Video';
import styles from './Settings.module.css';

const highlights = [8.5, 11, 14, 18, 22, 26];

const Settings = ({ data: { title, subtitle, video }, onBackgroundChange }) => {
  let videoRef = React.createRef();
  const [currentTime, setCurrentTime] = useState(0);

  const handleContinueClick = () => {
    document.getElementById('btnNext').click();
  };

  return (
    <ContentBox
      title={title}
      subtitle={subtitle}
      video={
        <Video video={video} ref={videoRef} onTimeUpdate={setCurrentTime} />
      }
    >
      <>
        <div className={styles.backgroundsContainer}>
          {[...Array(6)].map((empty, number) => {
            const highlightAt = highlights[number];
            const isHighlighted =
              currentTime >= highlightAt &&
              currentTime <= highlights[number] + 1;

            const buttonClass = cx(styles.button, {
              [styles.buttonIe11]: navigator.userAgent.indexOf('Trident') >= 0,
              [styles.buttonHighlighted]: isHighlighted
            });

            return (
              <button
                key={number}
                className={buttonClass}
                onClick={() => onBackgroundChange(number + 1)}
              >
                <div className={styles.imageContainer}>
                  <img
                    className={styles.image}
                    src={require(`../../assets/images/background0${number +
                      1}.jpg`)}
                    alt=""
                  />
                </div>
              </button>
            );
          })}
        </div>
        <p className={styles.paragraph}>
          If you wish to proceed without selecting a background please click
          Continue.
        </p>
        <div style={{ textAlign: 'center', marginTop: 15 }}>
          <button
            className={styles.continueButton}
            onClick={handleContinueClick}
          >
            Continue
          </button>
        </div>
      </>
    </ContentBox>
  );
};

Settings.propTypes = settingsPropTypes;

export default Settings;
