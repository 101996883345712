import React, { PureComponent } from 'react';
import cx from 'classnames';
import { withAlert } from 'react-alert';
import PropTypes from 'prop-types';
import VideoProcessor from './VideoProcessor';
import styles from './Video.module.css';

class Video extends PureComponent {
  static propTypes = {
    video: PropTypes.string.isRequired,
    onTimeUpdate: PropTypes.func,
    onLoadedMetaData: PropTypes.func
  };

  static width = 420;
  static height = 314;

  constructor(props) {
    super(props);

    this.videoProcessor = React.createRef();
    this.idleVideoProcessor = React.createRef();
    this.canvasBuffer = React.createRef();
    this.canvasOutput = React.createRef();
    this.interval = undefined;

    this.state = {
      duration: 0,
      currentTime: 0,
      showIdle: false,
      canPlay: false
    };

    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;

    this.video = this.videoProcessor.current.video.current;
    this.idleVideo = this.idleVideoProcessor.current.video.current;

    this.btnPlay = document.getElementById('btnPlay');
    this.btnPause = document.getElementById('btnPause');

    this.video.addEventListener('timeupdate', this.handleTimeUpdate, false);
    this.video.addEventListener(
      'loadedmetadata',
      this.handleMetaDataLoaded,
      false
    );
    this.video.addEventListener('canplay', this.handleCanPlay, false);
    this.video.addEventListener('play', this.handlePlay, false);
    this.video.addEventListener('ended', this.handleEnded, false);

    this.btnPlay.addEventListener('click', this.handlePlayButtonClick, false);
    this.btnPause.addEventListener('click', this.handlePauseButtonClick, false);

    const playReturn = this.video.play();

    if (playReturn) {
      playReturn
        .then(() => {
          if (this._isMounted) {
            this.showPauseButton();
          }
        })
        .catch(() => {
          if (this._isMounted) {
            this.showAutoPlayWarnings();
            this.showIdle();
            this.showPlayButton();
          }
        });
    } else {
      if (this._isMounted) {
        this.showPauseButton();
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;

    if (this.alert) {
      this.alert.close();
      this.stopShinePlayButton();
    }

    this.video.removeEventListener('canplay', this.handleCanPlay, false);
    this.video.removeEventListener('timeupdate', this.handleTimeUpdate, false);
    this.video.removeEventListener(
      'loadedmetadata',
      this.handleMetaDataLoaded,
      false
    );
    this.video.removeEventListener('play', this.handlePlay, false);
    this.video.removeEventListener('ended', this.handleEnded, false);

    this.btnPlay.removeEventListener(
      'click',
      this.handlePlayButtonClick,
      false
    );
    this.btnPause.removeEventListener(
      'click',
      this.handlePauseButtonClick,
      false
    );
  };

  handleMetaDataLoaded = () => {
    const duration = this.video.duration;

    this.setState({
      duration
    });

    if (this.props.onLoadedMetaData) {
      this.props.onLoadedMetaData(duration);
    }
  };

  handleCanPlay = () => {
    this.setState({
      canPlay: true
    });
  };

  handleEnded = () => {
    this.showPlayButton();
    this.showIdle(() => {
      this.video.pause(); // ie11 starts playing again, even if ended
      this.video.currentTime = 0;
      this.handleTimeUpdate();
    });
  };

  handleTimeUpdate = () => {
    const currentTime = this.video.currentTime;
    this.setState({ currentTime });

    if (this.props.onTimeUpdate) {
      this.props.onTimeUpdate(currentTime);
    }
  };

  handleSeek = time => {
    this.video.currentTime = time;
  };

  handlePlayButtonClick = () => {
    if (this.alert) {
      this.alert.close();
      this.stopShinePlayButton();
    }
    this.hideIdle(() => {
      this.video.play();
    });
    this.showPauseButton();
  };

  handlePauseButtonClick = () => {
    this.video.pause();
    this.showPlayButton();
  };

  showAutoPlayWarnings = () => {
    this.alert = this.props.alert.info(
      'Please press the play button to start the video.',
      { timeout: 0 }
    );
    this.startShinePlayButton();
  };

  startShinePlayButton = () => {
    const className = this.btnPlay.className;
    if (className.indexOf('shine') === -1) {
      this.btnPlay.className = `${className} shine`;
    }
  };

  stopShinePlayButton = () => {
    const className = this.btnPlay.className;
    if (className.indexOf('shine') >= 0) {
      this.btnPlay.className = className.replace('shine', '');
    }
  };

  showPlayButton = () => {
    this.btnPause.style.display = 'none';
    this.btnPlay.style.display = 'inline-block';
  };

  showPauseButton = () => {
    this.btnPlay.style.display = 'none';
    this.btnPause.style.display = 'inline-block';
  };

  showIdle = callback => {
    this.setState(
      {
        showIdle: true
      },
      () => {
        this.idleVideo.play();
        callback && callback();
      }
    );
  };

  hideIdle = callback => {
    this.setState(
      {
        showIdle: false
      },
      () => {
        this.idleVideo.pause();
        callback && callback();
      }
    );
  };

  render() {
    const { showIdle, canPlay } = this.state;
    return (
      <div className={styles.videoContainer} style={this.props.style || {}}>
        <div
          className={cx('', {
            [styles.edge]:
              /Edge/.test(navigator.userAgent) ||
              navigator.userAgent.indexOf('Trident') >= 0
          })}
          style={{
            display: !showIdle ? 'block' : 'none'
          }}
        >
          {!canPlay && (
            <div className={styles.loadCircleContainer}>
              <div className={styles.loadCircle}>
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle1)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle2)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle3)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle4)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle5)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle6)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle7)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle8)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle9)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle10)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle11)}
                />
                <div
                  className={cx(styles.loadCircleChild, styles.loadCircle12)}
                />
              </div>
            </div>
          )}
          <VideoProcessor video={this.props.video} ref={this.videoProcessor} />
        </div>
        <div
          className={cx('', { [styles.edge]: true })}
          style={{
            display: showIdle ? 'block' : 'none'
          }}
        >
          <VideoProcessor
            video={'idle.mp4'}
            videoAttributes={{
              muted: true,
              loop: true
            }}
            ref={this.idleVideoProcessor}
            outputCanvasStyle={{
              marginTop: -1
            }}
          />
        </div>
      </div>
    );
  }
}

export default withAlert()(Video);
