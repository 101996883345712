import React from 'react';
import { introPropTypes } from '../../config/prop-types';
import { ContentBox } from '../ContentBox';
import { Video } from '../Video';
import styles from './Intro.module.css';

const Intro = ({ data: { title, subtitle, video } }) => {
  const accountName = window.localStorage.getItem('account');

  const handleGetStartedClick = () => {
    document.getElementById('btnNext').click();
  };

  return (
    <div className={styles.introContainer}>
      <div className={styles.videoContainer}>
        <Video video={video} style={{ left: -110, bottom: 27 }} />
        <div className={styles.info}>
          <div className={styles.doctorCredentials}>
            <p className={styles.doctorCredentialsName}>Dr. Virginia Simpson</p>
            <p className={styles.doctorCredentialsJobTitle}>
              Executive Counseling Director
            </p>
          </div>
          {accountName && <p className={styles.account}>{accountName}</p>}
        </div>
      </div>
      <div className={styles.boxContainer}>
        <ContentBox
          title={title}
          titleStyle={{ textAlign: 'center' }}
          subtitle={subtitle}
          style={{
            alignItems: 'center',
            height: '100%',
            paddingBottom: 0,
            paddingTop: 77
          }}
        >
          <div style={{ textAlign: 'center', marginTop: 15 }}>
            <button
              className={styles.getStartedButton}
              onClick={handleGetStartedClick}
            >
              Get Started
            </button>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};

Intro.propTypes = introPropTypes;

export default Intro;
